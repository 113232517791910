import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import ArticleList from '../components/ArticleList'
import tachyons from 'tachyons-components'
import Slugify from '../utils/slugify'

import BannerCards from '../components/BannerCards'
import SEO from '../components/seo'

const ButtonSecondary = tachyons(Link)`

`
const ButtonDisabled = tachyons('a')`

`

const Pagination = tachyons('div')`
mb5 pt3 pagination is-medium is-centered
`

const PaginationLink = props => {
  if (!props.test) {
    return (
      <ButtonSecondary to={`/news/${props.url}`} className={props.className}>
        {`${props.text}`}
      </ButtonSecondary>
    )
  } else {
    return (
      <ButtonDisabled className={props.className} disabled>
        {props.text}
      </ButtonDisabled>
    )
  }
}

export default class IndexPage extends React.Component {
  render() {
    const tagGroup = this.props.data.allMarkdownRemark.group
    const recentPosts = this.props.data.recentposts.edges
    const { location, pageContext } = this.props
    const { group, index, pageCount, first, last } = pageContext
    const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString() + '/'
    const pageNumbers = new Array(pageCount)
      .fill(undefined)
      .map((_, index) => index + 1)

    const bannerinfo = {
      cards: [
        {
          headline: 'News',
          text: '',
        },
      ],
    }

    const bgimage = this.props.data.desktop.childImageSharp.fluid

    return (
      <Layout>
        <div className="">
          <SEO
            title={`News | ${this.props.data.site.siteMetadata.title}`}
            description={`${this.props.data.site.siteMetadata.newsDescription}`}
            type="website"
          />
          <div className="banner-image--container banner-image--container--short">
            <Img
              className="full-width-image margin-top-0 banner-image banner-image--shorter"
              fluid={bgimage}
              backgroundColor={`#1d3d6d`}
            />
          </div>
          <div className="content-background">
            <div className="banner-card__container banner-card__container--short">
              <BannerCards rowItems={bannerinfo.cards} className={''} />
            </div>
            <section className="section section--gradient section--short section--news">
              <div className="container">
                <div className="columns is-desktop">
                  <div className="column is-8-desktop is-12-mobile">
                    <ArticleList posts={group} />
                    <Pagination>
                      {!first && (
                        <PaginationLink
                          className="pagination-link pagination-previous"
                          test={first}
                          url={previousUrl}
                          text="Previous"
                        />
                      )}
                      {!last && (
                        <PaginationLink
                          className="pagination-link pagination-next"
                          test={last}
                          url={nextUrl}
                          text="Next page"
                        />
                      )}
                      <ul className="pagination-list">
                        {pageNumbers.map(number => {
                          const isActive =
                            location.pathname.indexOf(number) > -1 ||
                            (location.pathname === '/news/' && number === 1)
                          return (
                            <li key={number}>
                              <PaginationLink
                                className={
                                  isActive === true
                                    ? 'pagination-link is-current'
                                    : 'pagination-link'
                                }
                                test={isActive}
                                url={`/${number === 1 ? '' : number}`}
                                text={number}
                              />
                            </li>
                          )
                        })}
                      </ul>
                    </Pagination>
                  </div>
                  <div className="column is-4-desktop is-12-mobile news-sidebar">
                    <div className="">
                      <h3 className="news-sidebar__heading">Latest News</h3>
                      {recentPosts.map(post => {
                        return (
                          <div className="card mini-card" key={post.node.id}>
                            <div className="card-content">
                              <div className="mini-card__title is-size-5">
                                <a
                                  className=""
                                  href={`${post.node.fields.slug}`}
                                >
                                  {post.node.frontmatter.title}
                                </a>
                              </div>
                              <div className="mini-card__tags is-size-6 card-content__tag">
                                {post.node.frontmatter.tags.map(tag => (
                                  <span key={tag}>
                                    <a href={`/tags/${Slugify(tag)}`}>{tag}</a>
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="">
                      <h3 className="news-sidebar__heading">Tags</h3>
                      <div className="card mini-card">
                        <div className="card-content card-content--list">
                          {tagGroup.map(item => {
                            return (
                              <div
                                className="card-content__tag"
                                key={item.fieldValue}
                              >
                                <a
                                  className=""
                                  href={`/tags/${Slugify(item.fieldValue)}`}
                                >
                                  {item.fieldValue} ({item.totalCount})
                                </a>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query NewsQuery {
    desktop: file(relativePath: { eq: "news-hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        author
        title
        description
        homeDescription
        contractVehiclesDescription
        newsDescription
        contactDescription
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "news-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            tags
          }
        }
      }
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    recentposts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
      filter: { frontmatter: { templateKey: { eq: "news-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            tags
          }
        }
      }
    }
  }
`
